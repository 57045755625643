.prices .animal__title {
  text-align: center;
}

.card {
  max-width: 35rem;
}

p {
    margin-left: 3rem;
    margin-right: 2rem;
}

@media (max-width: 1024px) {
    p {
      margin: 10px;
    }
  }

@media (max-width: 1024px) {
  .card {
    max-width: 95%;
  }
}
