.hunting-time-title {
  text-align: left;
  margin-left: 3rem;
  font-size: 21px;
}

.hunting-times__section {
  padding-left: 0rem;
}

.card {
  max-width: 35rem;
}

@media (max-width: 1024px) {
  .card {
    max-width: 95%;
  }

  .hunting-times__section {
    padding-left: .5rem;
  }

  .hunting-time-title {
    text-align: left;
    margin-left: .5rem;
    font-size: 21px;
  }
}
