.phone-icon,
.email-icon {
  color: black;
  text-align: center;
  margin-right: 10px;
  margin-left: 20px;
}

.address-icon {
  color: black;
  text-align: center;
  margin-right: 11px;
  margin-left: 22px;
}

.phonenumber,
.email,
.address {
  text-decoration: none;
  color: black;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.person {
  font-size: 18px;
  color: #333;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 10px 0px 15px 0px;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

@media (max-width: 1024px) {
  .person {
    font-size: 17px;
    padding: 10px 0px 15px 0px;
  }
}
