
.react-select-container {
  margin: .5rem;
}

.language__options {
  display: flex;
  align-items: center;
  border-radius: 7px;
}

@media (max-width: 1160px) {
  .react-select-container {
    margin: 0rem;
    max-width: 6rem;
    text-align: right;
  }
}