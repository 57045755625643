.background {
  position: relative;
  border-radius: 8px;
  width: 80%;
  margin: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  top: 2vh;
  padding-bottom: 5rem;
}

@media (max-width: 1024px) {
  .background {
    width: 95%;
    padding-bottom: 3rem;
  }
}
