.title {
  font-weight: lighter;
  color: black;
  margin: .5rem auto;
  text-align: center;
  /* padding-top: 3rem; */
  font-size: 2.8rem;
  /* letter-spacing: 0.rem; */
  line-height: 2;
}

@media (max-width: 1024px) {
  .title {
    padding-top: 1rem;
    letter-spacing: 0.1rem;
    line-height: 1;
    font-size: 2.2rem;
    margin: .7rem auto;
  }

  .title h2 {
    padding-right: 0 !important;
  }
}
