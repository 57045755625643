.site-title {
  margin: 0.5rem 2.5rem;
}

.site-title h2 {
  margin: 0;
  padding: 0;
  padding-right: 2.6rem;
}

.site-title p {
  margin: 0;
  padding: 0;
}

.site-title h2 a {
  text-decoration: none;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #394d55;
}

.site-title p a {
  font-family: "Karla";
}

@media (max-width: 1024px) {
  .site-title {
    margin: 0.5rem 1rem;
    margin-right: 0rem;
  }

  .site-title h2 {
    padding-right: 0rem;
  }
}

@media (max-width: 380px) { 
  .site-title {
    margin: 0.5rem 0.5rem;
    /* margin-right: 0.3rem; */
    padding-right: 0.8rem;
  }
}
