.calendar-icon {
  color: black;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

.title {
  margin-left: 5px;
  font-size: 15px;
  color: #333;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 1024px) {
  .title {
    margin-left: 4px;
    font-size: 14px;
  }

  .calendar-icon {
    margin-right: 3px;
    margin-left: 6px;
  }
}
