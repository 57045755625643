/* .intro {
    position: relative;
} */

.description {
  margin: auto;
  width: 85%;
  max-width: 85%;
  padding: 10px;
}

.description p {
  text-align: left;
  color: black;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  font-family: "Lucida Console", Monaco, monospace;
}

.review {
  text-align: center;
}

.image-gallery-wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 1rem auto;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.videos {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  text-align: center;
}

@media (max-width: 1024px) {
  .description {
    width: 90%;
    max-width: 90%;
    padding: 0px;
  }

  .description p {
    font-size: 1rem;
  }

  .image-gallery-wrapper {
    width: 90%;
  }

  .videos {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
  }
}
