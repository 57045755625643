.spinner-container {
    width: fit-content;
    margin: auto;
}

.image-container {
    text-align: center;
}

.image{
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-bottom: .3rem;
    width: 49%;
}

@media (max-width: 1024px) {
    .image {
      width: 95%;
      margin-bottom: .08rem;
    }
  }