.card {
  background-color: #fff;
  border: 1px solid #ccc;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .card {
    max-width: 75%;
    margin: auto;
    margin-bottom: 10px;
  }
}
