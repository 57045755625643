.file-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.file-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card {
  padding: 20px !important;
  max-width: 100rem !important;
  width: 45.5% !important;
}

@media (max-width: 1024px) {
  .card {
    max-width: 100% !important;
    width: 85% !important;
    margin: auto !important;
    margin-bottom: 6px !important;
  }
}
