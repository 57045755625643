.price-table {
    border-collapse: collapse;
    border-radius: .5em;
    overflow: hidden;
    margin: 25px auto;
    font-size: 0.9em;
    font-family: sans-serif;
    /* min-width: 400px; */
    width:85%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    
  }
  
  .price-table thead tr {
      background-color: #1f6357;
      color: #ffffff;
      text-align: left;
  }
  
  .price-table th,
  .price-table td {
      padding: 12px 15px;
  }
  
  .price-table tbody tr {
      border-bottom: 1px solid #dddddd;
  }
  
  .price-table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
  }
  
  
  @media (max-width: 1024px) {
    .price-table {
      width: 95%;
    }
  }