.header {
  margin-bottom: 0;
  padding-bottom: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.navbar-links ul {
  margin-bottom: 0;
  padding-bottom: 0;
  display: flex;
}

.navbar-links {
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.navbar-links a {
  text-decoration: none;
  padding: 1rem;
  color: #394d55;
  display: block;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 0;
}

.navbar-links a:hover {
  color: cadetblue;
}

.navbar-links a.active {
  color: cadetblue;
}

@media (max-width: 1160px) {
  .header {
    text-align: left;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: block;
  }

  .navbar-links ul {
    padding-top: 3rem;
    flex-direction: column;
    list-style-type: none;
    align-items: flex-end;
  }

  .language {
    margin-top: 0.8rem;
    margin-right: 1rem;
  }

  .navbar-links a {
    padding-left: 0rem !important;
  }
}
