.toggle-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 2.5rem;
  height: 1.5rem;
  border: none;
  background-color: transparent;
}

.toggle-button .bar {
  height: 0.25rem;
  width: 100%;
  background-color: black;
  border-radius: 10px;
}

@media (max-width: 1160px) {
  .toggle-button {
    display: flex;
    width: 3rem;
    height: 1.5rem;
  }
}
