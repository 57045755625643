a {
  text-decoration: none;
}

.file-icon {
  color: black;
  text-align: center;
  margin-right: 10px;
}

.download-icon {
  color: black;
  text-align: center;
  margin-left: 10px;
}

.file-name {
  font-size: 18px;
  color: #333;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 1024px) {
  .file-name {
    font-size: 17px;
  }

  .file-icon {
    color: black;
    text-align: center;
    margin-right: 9px;
  }

  .download-icon {
    color: black;
    text-align: center;
    margin-left: 9px;
  }
}
