.guest-house {
  margin-left: 6rem;
  margin-right: 6rem;
}

.app .image-gallery,
.app-sandbox {
  margin: 0 auto;
  width: 65%;
}

.rooms {
  margin-left: 5px;
}

.food {
  margin: 0px !important;
  width: 55% !important;
}

.image-gallery-wrapper {
  margin-top: 5rem;
  max-width: 1100px;
  width: 100%;
  margin: 3rem auto;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.spinner-container {
  width: fit-content;
  margin: auto;
}

@media only screen and (min-device-width: 375px) {
  .header-wrapper {
    padding: 1rem;
  }
}

@media (max-width: 1024px) {
  .guest-house {
    margin: 10px;
  }

  .food {
    margin: 0px !important;
    width: 100% !important;
  }
}
